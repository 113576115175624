import { Injectable } from "@angular/core";
import Axios from "axios";
import { ToastController, LoadingController } from "@ionic/angular";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(
    private router: Router,
    public toastController: ToastController,
    public loadingController: LoadingController
  ) { }

  user = null
  error = null;

  async validate(payload) {
    let loader = await this.loadingController.create({
      message: 'Espere un momento'
    })
    loader.present()
    const response = await Axios("https://api.vitemconsultores.com/usuario/verificacion", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      data: JSON.stringify({
        codigoverificacion: payload.pin
      })
    })
    const Data = await response.data
    loader.dismiss()
    if (Data.code === '400') {
      const toast = await this.toastController.create({
        header: Data.message,
        position: "bottom",
        duration: 3000,
      })
    } else if (Data.code === '200') {
      this.router.navigate(["/user/panel"]);
    };

  }

  async create(payload) {
    let loader = await this.loadingController.create({
      message: "Espere un momento...",
    });
    loader.present();
    const response = await Axios(
      "https://api.vitemconsultores.com/usuario/crear",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          nombreyapellido: payload.nombres,
          telefono: payload.tel,
          email: payload.email,
          password: payload.pass,
          direccion: payload.direccion,
          localidad: payload.localidad,
          tmptoken: this.user.tmptoken,
        }),
      }
    );
    const Data = await response.data;
    loader.dismiss();
    if (Data.code === "400") {
      const toast = await this.toastController.create({
        header: Data.message,
        position: "bottom",
        duration: 3000,
      });
      this.error = true
      toast.present();
    }
    if (Data.code === '200') {
      const toast = await this.toastController.create({
        header: Data.message,
        position: "bottom",
        duration: 3000,
      })
      toast.present();
    }
  }

  async login(payload) {
    let loader = await this.loadingController.create({
      message: "Espere un momento...",
    });
    loader.present();
    const response = await Axios(
      "https://api.vitemconsultores.com/usuario/validaciondeususario",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          matricula: payload.matricula,
          password: payload.password,
        }),
      }
    );
    loader.dismiss();
    const Data = await response.data;
    console.log(Data)
    if (Data.code === "400") {
      const toast = await this.toastController.create({
        header: Data.message,
        position: "bottom",
        duration: 3000,
      });
      toast.present();
    } else {
      this.user = Data.data;
      if (this.user.token) {
        this.router.navigate(["/user/panel"]);
      } else if (this.user.tmptoken) {
        this.router.navigate(["/user/new"]);
      }
    }
  }
}
